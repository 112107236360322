/**
 * Truncates given text if it exceeds a specified maximum length, appending a shrink indicator.
 *
 * @param text The input text to be truncated.
 * @param maxLength The maximum number of characters allowed in the truncated text.
 * @param shrinkIndicator The indicator appended to the truncated text, signaling that the text has been shortened.
 * @returns The truncated text, if applicable; otherwise, the original text.
 */
export const truncate = (text: string, maxLength: number, shrinkIndicator = '...') => {
  if (text.length <= maxLength) return text;

  return text.slice(0, maxLength - shrinkIndicator.length) + shrinkIndicator;
};
