import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { type SearchResultItem } from '@/pages/api/globalSearch';
import { routes } from '@/utils/routes';
import { useRouteChange } from '@/utils/useRouteChange';
import {
  Box,
  Flex,
  IconButton,
  Spinner,
  Text,
  VStack,
  useOutsideClick,
  useTheme,
  type PositionProps,
} from '@chakra-ui/react';
import { SearchIcon } from '@sphere/icons';
import { AnimatePresence } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import qs from 'qs';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type FC,
} from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { useDebounce } from 'react-use';
import { SearchChainSwitcher } from '../SearchChainSwitcher';
import { SearchBackDrop } from './SearchBackdrop';
import SearchBarForm, { SearchBarFormProps } from './SearchBarForm';
import SearchHit from './SearchHit/SearchHit';
import { SearchHits } from './SearchHits';
import { SearchResultsScroller, SearchResultsWrapper } from './SearchResultsWrapper';
import { useFocus } from './useFocus';

const SEARCH_MIN_CHARACTERS = 2;
const MAX_GAME_RESULTS = 3;
const MAX_COLLECTION_RESULTS = 3;

type GlobalSearchProps = Pick<SearchBarFormProps, 'variant'> & Pick<PositionProps, 'zIndex'>;

const GlobalSearch: FC<GlobalSearchProps> = ({ variant, zIndex }) => {
  const marketplaceChain = useMarketplaceChain();
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { focusedIndex, setFocusedIndex, setTotalItems } = useFocus();
  const router = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isSearching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchChain, setSearchChain] = useState(marketplaceChain.routePrefix);
  const [results, setResults] = useState<SearchResultItem[]>([]);
  const [isBackdropOpen, setBackdropOpen] = useState(false);

  useEffect(() => {
    setSearchChain(marketplaceChain.routePrefix);
  }, [marketplaceChain.routePrefix]);

  /** For larger screens, we want the autocomplete to be open when the user is typing */
  const isSearchOpen = useMemo(
    () => isBackdropOpen || !!searchQuery,
    [isBackdropOpen, searchQuery],
  );

  const hasResults = results.length > 0;

  const [hasDebounced] = useDebounce(
    () => {
      const getSearchResults = async () => {
        setSearching(true);

        const res = await fetch(
          `/api/globalSearch?query=${searchQuery}&searchChain=${searchChain}`,
        ).then(res => res.json());

        setResults(res.results);
        setSearching(false);
      };

      if (searchQuery.length >= SEARCH_MIN_CHARACTERS) {
        getSearchResults();
      } else {
        setResults([]);
      }
    },
    250,
    [searchQuery, searchChain],
  );

  /**
   * Clears the input, and therefore closes the backdrop
   */
  const onReset = useCallback(() => {
    if (!searchQuery.length) {
      inputRef.current?.blur();
    }

    setBackdropOpen(false);
    setSearchQuery('');
    setFocusedIndex(() => 0);
  }, [searchQuery.length, setFocusedIndex]);

  /**
   * Updates input state / search query
   */
  const onChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  }, []);

  /**
   * Outside click handler - click on the backdrop will result in the overlay to be closed
   */
  useOutsideClick({
    ref: wrapperRef,
    handler: onReset,
  });

  useEffect(() => {
    if (focusedIndex > 0 || !inputRef.current || !searchQuery) return;
    inputRef.current.focus();
  }, [focusedIndex, searchQuery]);

  /**
   * Handles pressing on enter
   */
  const onSubmit = useCallback(() => {
    const href = {
      pathname: routes.search,
      query: qs.stringify(
        {
          q: searchQuery,
          chain: searchChain,
        },
        { encode: false },
      ),
    };

    /**
     * If user is already on the search page, we want to shallow route so data fetching methods are not run again. On the search page itself,
     * a useEffect listens to changes in the query and will query for results.
     */
    if (router.pathname.startsWith(routes.search)) {
      router.push(href, undefined, { shallow: true });
    } else {
      router.push(href);
    }
  }, [searchQuery, searchChain, router]);

  /**
   * Effect handles closing the backdrop after clicking escape
   */
  useEffect(() => {
    function handleKeys(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onReset();
      }
    }

    window.addEventListener('keydown', handleKeys);

    return () => {
      window.removeEventListener('keydown', handleKeys);
    };
  }, [onReset]);

  /**
   * Effects handles the closing of the backdrop after clicking on a result
   */
  useRouteChange('routeChangeStart', onReset);

  useEffect(() => {
    setTotalItems((results?.length || -1) + 2);
  }, [results.length, setTotalItems]);

  const games = results.filter(result => result.type === 'game').slice(0, MAX_GAME_RESULTS);

  const collections = results
    .filter(result => result.type === 'collection')
    .slice(0, MAX_COLLECTION_RESULTS);

  return (
    <>
      <IconButton
        display={{
          base: 'flex',
          md: 'none',
        }}
        zIndex={zIndex}
        variant="icon"
        color="inherit"
        background="none"
        type="button"
        width={rem(40)}
        height={rem(40)}
        onClick={() => setBackdropOpen(true)}
        aria-label={t('search-bar.input.clear-aria-label')}
        isRound
        icon={<SearchIcon color="currentColor" />}
        data-testid="autocomplete-mobile-trigger"
      />

      <RemoveScroll enabled={isSearchOpen} removeScrollBar={false}>
        <FocusLock disabled={!isSearchOpen} returnFocus>
          <AnimatePresence>
            {!!isSearchOpen && (
              <SearchBackDrop
                data-testid="autocomplete-backdrop"
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{
                  duration: 0.1,
                }}
              />
            )}
          </AnimatePresence>
          <Box
            ref={wrapperRef}
            zIndex="autocomplete"
            position={{
              base: 'fixed',
              md: 'relative',
            }}
            left={{
              base: 'space.20',
              md: 'inherit',
            }}
            top={{
              base: rem(50),
              md: 'inherit',
            }}
            display={{
              base: isBackdropOpen ? 'block' : 'none',
              md: 'block',
            }}
          >
            <SearchBarForm
              inputRef={inputRef}
              onSubmit={onSubmit}
              onChange={onChange}
              onReset={onReset}
              value={searchQuery}
              variant={variant}
              withShortcut
            />
            <Box
              position="absolute"
              top={`calc(100% + ${theme.space['space.16']})`}
              width="100%"
              minWidth={rem(320)}
            >
              <AnimatePresence>
                {searchQuery.length >= SEARCH_MIN_CHARACTERS && (
                  <Box
                    width={{
                      md: rem(400),
                      lg: rem(575),
                    }}
                  >
                    <SearchResultsWrapper
                      data-testid="autocomplete-results"
                      initial={{
                        y: 50,
                        opacity: 0,
                      }}
                      animate={{
                        y: 0,
                        opacity: 1,
                      }}
                      transition={{
                        duration: 0.1,
                      }}
                    >
                      <SearchResultsScroller>
                        <SearchChainSwitcher
                          searchChain={searchChain}
                          onChangeChain={chain => setSearchChain(chain)}
                          mb="space.16"
                        />
                        {hasResults && hasDebounced() && (
                          <VStack align="stretch" spacing="space.16" mb="space.8">
                            {!!games.length && (
                              <SearchHits
                                title={t('autocomplete.results.categories.game.title')}
                                viewAllLink={{
                                  shallow: false,
                                  href: {
                                    pathname: routes.search,
                                    query: qs.stringify(
                                      {
                                        q: searchQuery,
                                        chain: searchChain,
                                        tab: 'games',
                                      },
                                      { encode: false },
                                    ),
                                  },
                                  label: t('autocomplete.results.categories.game.view-all'),
                                }}
                                focusIndex={
                                  Math.min(results?.length ?? 0, MAX_COLLECTION_RESULTS) + 1
                                }
                              >
                                {games.map((result, i) => (
                                  <SearchHit
                                    key={result.data.id}
                                    result={result}
                                    focusIndex={i + 1}
                                  />
                                ))}
                              </SearchHits>
                            )}
                            {!!collections.length && (
                              <SearchHits
                                title={t('autocomplete.results.categories.collection.title')}
                                viewAllLink={{
                                  shallow: false,
                                  href: {
                                    pathname: routes.search,
                                    query: qs.stringify(
                                      {
                                        q: searchQuery,
                                        chain: searchChain,
                                        tab: 'collections',
                                      },
                                      { encode: false },
                                    ),
                                  },
                                  label: t('autocomplete.results.categories.collection.view-all'),
                                }}
                                focusIndex={
                                  Math.min(results?.length ?? 0, MAX_COLLECTION_RESULTS) + 1
                                }
                              >
                                {collections.map((result, i) => (
                                  <SearchHit
                                    key={result.data.id}
                                    result={result}
                                    focusIndex={i + 1}
                                  />
                                ))}
                              </SearchHits>
                            )}
                          </VStack>
                        )}

                        {(isSearching || !hasDebounced()) && (
                          <Flex justifyContent="center" alignItems="center" py="space.20">
                            <Spinner size="sm" />
                            <Text color="gray.5" pl="space.8">
                              {t('autocomplete.results.searching')}
                            </Text>
                          </Flex>
                        )}

                        {hasDebounced() &&
                          !isSearching &&
                          !hasResults &&
                          searchQuery.length >= SEARCH_MIN_CHARACTERS && (
                            <Flex
                              justifyContent="center"
                              data-testid="autocomplete-empty-state"
                              py="space.20"
                            >
                              <Text as="span" color="gray.5">
                                {t('autocomplete.results.not-found')}
                              </Text>
                            </Flex>
                          )}
                      </SearchResultsScroller>
                    </SearchResultsWrapper>
                  </Box>
                )}
              </AnimatePresence>
            </Box>
          </Box>
        </FocusLock>
      </RemoveScroll>
    </>
  );
};

GlobalSearch.displayName = 'GlobalSearch';

export default GlobalSearch;
