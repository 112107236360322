export enum SocialMediaType {
  Twitter = 'twitter',
  Instagram = 'instagram',
  Discord = 'discord',
  Medium = 'medium',
  Youtube = 'youtube',
  Reddit = 'reddit',
  Email = 'email',
  Telegram = 'telegram',
}

type SocialMediaProfile = {
  name: string;
  type: SocialMediaType;
  url: string;
};

const twitter: SocialMediaProfile = {
  name: 'BuildOnBeam',
  type: SocialMediaType.Twitter,
  url: 'https://twitter.com/BuildOnBeam',
};

const discord: SocialMediaProfile = {
  name: 'meritcircle',
  type: SocialMediaType.Discord,
  url: 'https://discord.com/invite/meritcircle',
};

export const telegram: SocialMediaProfile = {
  name: '@meritcircle',
  type: SocialMediaType.Telegram,
  url: 'https://t.me/meritcircle',
};

const medium: SocialMediaProfile = {
  name: '@meritcircle',
  type: SocialMediaType.Medium,
  url: 'https://medium.com/@meritcircle',
};

export const email: SocialMediaProfile = {
  name: 'build@onbeam.com',
  type: SocialMediaType.Email,
  url: 'mailto:build@onbeam.com',
};

export const socialMediaProfiles = [twitter, discord, telegram, medium];
