import { WalletIcon } from '@/components/shared/WalletIcon';
import { Button, Flex, Image, Text, Tooltip, type ButtonProps } from '@chakra-ui/react';
import { rem } from 'polished';
import { type Connector } from 'wagmi';

type Props = {
  connector: Connector;
  tooltip?: string;
} & ButtonProps;

export const WalletListItem = ({ connector, tooltip, children, ...buttonProps }: Props) => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify="flex-start" align="center">
      {connector.icon ? (
        <Image
          src={connector.icon}
          alt={connector.name}
          w="full"
          maxW={{ base: rem(100), md: rem(38) }}
          maxH={{ md: rem(38) }}
        />
      ) : (
        <WalletIcon
          aria-hidden={true}
          connectorId={connector.id}
          maxW={{ base: rem(100), md: rem(38) }}
          maxH={{ md: rem(38) }}
        />
      )}

      <Text
        as="span"
        size="lg"
        fontWeight="medium"
        mt={{ base: 'space.4', md: 0 }}
        ml={{ md: 'space.12' }}
        mr={{ md: 'auto' }}
      >
        {connector.name}
      </Text>
      <Tooltip label={tooltip} isDisabled={!tooltip} hasArrow arrowSize={8} placement="top">
        <Button
          w={{ base: '100%', md: '12rem' }}
          mt={{ base: 'space.32', md: 0 }}
          data-testid={`${connector.id}-connect-button`}
          variant="primary"
          {...buttonProps}
        >
          {children}
        </Button>
      </Tooltip>
    </Flex>
  );
};
