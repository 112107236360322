import { BigNumber, constants, utils } from 'ethers';

export type TokenAmountValue = BigNumber | string | number;

export type TokenAmountDisplayFormat = 'base' | 'loose' | 'compact' | 'fiat' | 'exact';

const displayFormatMap: { [key in TokenAmountDisplayFormat]: string } = {
  base: '0x3b9aca00', // Rounds to a maximum of 9 decimals (1e9, default)
  loose: '0xe8d4a51000', // Rounds to a maximum of 6 decimals (1e12)
  compact: '0x5af3107a4000', // Rounds to a maximum of 4 decimals (1e14)
  fiat: '0x2386f26fc10000', // Rounds to a maximum of 2 decimals (1e16)
  exact: '0x0de0b6b3a7640000', // No decimals (1e18)
};

/**
 * Used to compare with token amounts to determine if it should get rounded.
 * Contains a hex string that equals to 0.1 for the 'exact' amount,
 * unlike displayFormatMap where it equals 1.0.
 */
const amountThreshold = {
  base: '0x3b9aca00', // 0.000000001
  loose: '0xe8d4a51000', // 0.000001
  compact: '0x5af3107a4000', // 0.0001
  fiat: '0x2386f26fc10000', // 0.01
  exact: '0x016345785d8a0000', // 0.1
};

const { formatEther, parseEther, commify } = utils;

/**
 * Formats a token amount to a commified string with the correct number of digits. Handles BigNumberish input and regular numbers as a fallback.
 * It also converts numbers in scientific notation (1e-9), which converts it to it string equivalent (0.000000001). This support is
 * mainly needed for numbers passed from services that do not support BigNumber, like Elastic.
 * @param tokenAmount can be a number (1.2), a string number ('1.2'), a hex string ('0x271bb7b9b000') or a BigNumber
 * @param displayFormat defines how the returned value is formatted
 * @returns string representation of the value
 */
export function formatTokenAmount(
  tokenAmount: TokenAmountValue = '',
  displayFormat: TokenAmountDisplayFormat = 'base',
  prefix = '',
): string {
  try {
    const parsed = parse(tokenAmount);

    // If the parsed value is smaller than what the thresholds defines for the given display format, then
    // display only the threshold value prepended with a '<'. Zero values are skipped.
    if (parsed.lt(amountThreshold[displayFormat]) && !parsed.eq(constants.Zero)) {
      return `< ${prefix}${formatEther(amountThreshold[displayFormat])}`;
    }

    return `${prefix}${commify(format(parsed, displayFormat))}`;
  } catch {
    // Unable to parse value, return as string
    return `${prefix}${tokenAmount.toString()}`;
  }
}

/**
 * Parses a given value into a BigNumber
 * @param value
 * @returns BigNumber
 * @throws whenever BigNumber encounters an error
 */
function parse(value: TokenAmountValue): BigNumber {
  if (BigNumber.isBigNumber(value)) {
    return value;
  }

  // If the value is a hex string, parse it as a BigNumber
  if (typeof value === 'string' && value.startsWith('0x')) {
    return BigNumber.from(value);
  }

  // In the case a number is passed which cannot be presented accurately, and is thus displayed with scientific notatio,
  // (e.g. 1e12 or numbers with many zero decimals), then here we ensure it's still converted to a correct BigNumber
  if (`${value}`.includes('e')) {
    return BigNumber.from(
      (Number(value) * Math.pow(10, 18)).toLocaleString('fullwide', {
        useGrouping: false,
      }),
    );
  }

  // In all other cases, attempt to parse the value to a BigNumber
  return parseEther(`${Number(value).toFixed(18)}`);
}

/**
 * Formats a given BigNumber to ethers by rounding it according to given display format
 * @param value
 * @param displayFormat
 * @returns string
 */
function format(value: BigNumber, displayFormat: TokenAmountDisplayFormat): string {
  const rounded = value.sub(value.mod(displayFormatMap[displayFormat]));

  // If the rounded value is equal to zero, the original value is returned
  // because we ideally never display a rounded token amount as just '0'
  if (rounded.eq(constants.Zero)) {
    return formatEther(value);
  }

  // Using `formatEthers` will always append `.0` which we do not want to display
  // when using exact display format
  if (displayFormat === 'exact') {
    return formatEther(rounded).slice(0, -2);
  }

  return formatEther(rounded);
}

/**
 * Used to indicate if a certain token amount would get rounded based on the chosen display format.
 * @param tokenAmount can be a number (1.2), a string number ('1.2'), a hex string ('0x271bb7b9b000') or a BigNumber
 * @param displayFormat defines how the returned value is formatted
 * @returns boolean
 */
export const shouldRoundTokenAmount = (
  tokenAmount: TokenAmountValue,
  displayFormat: TokenAmountDisplayFormat = 'base',
) => {
  const parsed = parse(tokenAmount);
  return parsed.lt(amountThreshold[displayFormat]) && !parsed.eq(constants.Zero);
};
