import { formatWalletAddress } from '@sphere/ui';
import { isAddress, zeroAddress } from 'viem';

/**
 * This function converts both addresses to lowercase before comparing them. This ensures that
 * the comparison will be case-insensitive, as Ethereum addresses are often stored in a variety of casing.
 * This helps to prevent issues with address comparison due to accidental differences in casing.
 */
export function compareAddresses(a: string = '', b: string = ''): boolean {
  if (!a || !b) return false;
  return normalizeAddress(a) === normalizeAddress(b);
}

/**
 * lower cases the address since different providers might return different cases
 * we use this function so in case we wanted to change the normalizer we have a single source of truth
 * used for both input and output
 * @param address
 */
export function normalizeAddress(address: string = ''): string {
  return address?.toLowerCase();
}

/**
 * Formats the collection name based on the provided name.
 * If the name is an address, it formats the wallet address.
 * Otherwise, it returns the name as is.
 *
 * @param name - The name to be formatted.
 * @returns The formatted collection name.
 */
export const formatCollectionName = (name: string = '') =>
  isAddress(name) ? formatWalletAddress(name) : name;

/**
 * Checks if the given address is a token burn address.
 * @param address - The address to check.
 * @returns `true` if the address is a token burn address, `false` otherwise.
 */
export const isTokenBurnAddress = (address: string) =>
  [zeroAddress, '0x000000000000000000000000000000000000dead'].some(burnAddress =>
    compareAddresses(burnAddress, address),
  );
