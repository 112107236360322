import {
  GetCollectionActivityV6Response,
  GetTokensTokenActivityV5Params,
  useGetTokensTokenActivityV5Infinite,
} from '@/lib/reservoir/reservoir.generated';
import { isDefined } from '@/types/type-presence';
import { useMarketplaceChain } from './use-marketplace-chain';

export const ACTIVITY_ITEMS_PER_PAGE = 9;

const params: Partial<GetTokensTokenActivityV5Params> = {
  limit: ACTIVITY_ITEMS_PER_PAGE,
  includeMetadata: true,
  excludeSpam: true,
};

const useTokenActivity = (
  tokenId: string,
  contractAddress: string,
  override?: Partial<GetTokensTokenActivityV5Params>,
) => {
  const chain = useMarketplaceChain();

  const token = `${contractAddress}:${tokenId}`;

  const { data, ...request } = useGetTokensTokenActivityV5Infinite(
    token,
    {
      ...params,
      ...override,
    },
    {
      query: {
        enabled: !!token,
        queryKey: [`/tokens/${token}/activity/v5`, chain.id, token, params, override],
        getNextPageParam: (lastPage: GetCollectionActivityV6Response) =>
          lastPage.continuation || undefined,
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );

  return {
    data: (data?.pages || []).flatMap(page => page.activities).filter(isDefined),
    ...request,
  };
};

export { useTokenActivity };
