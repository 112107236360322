import { useCoinConversion as kitUseCoinConversion } from '@sphere/reservoir-kit-ui';

/**
 * Immutable doesn't have a separate coingecko id for WIMX, so the api is called with the same id twice.
 * It only returns a single item in that case though, so we're not getting a conversion rate for WIMX eventually.
 * We're adding it in here by duplicating the IMX conversion object, until someone has a better idea on how to deal with this.
 */

const tokensWithDuplicateCoingeckoIds: Record<string, string[]> = {
  IMX: ['WIMX'],
};

export const useCoinConversion = (vs_currency?: string, symbols?: string, id?: string) => {
  const result = kitUseCoinConversion(vs_currency, symbols, id);

  Object.keys(tokensWithDuplicateCoingeckoIds).forEach(mainSymbol => {
    const mainConversion = result.find((item: any) => item.symbol === mainSymbol);
    if (mainConversion) {
      tokensWithDuplicateCoingeckoIds[mainSymbol].forEach(symbol => {
        if (!result.find((item: any) => item.symbol === symbol)) {
          result.push({
            ...mainConversion,
            symbol,
          });
        }
      });
    }
  });

  return result;
};
