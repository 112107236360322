import { env } from '@/env.mjs';
import { BeamConfiguration, Environment } from '@onbeam/sdk';

const environment = env.NEXT_PUBLIC_BEAM_ENVIRONMENT || Environment.PREVIEW;
const publishableKey = `${env.NEXT_PUBLIC_BEAM_PUBLISHABLE_KEY}`;

const config = new BeamConfiguration({
  environment,
  publishableKey,
  debug: environment === Environment.PREVIEW,
});

export { config };
