import { ChakraTheme } from '@chakra-ui/react';

export const zIndices: ChakraTheme['zIndices'] = {
  hidden: -1,
  behind: 10,
  default: 50,
  'in-front': 100,
  header: {
    menu: 301,
    behind: 302,
    compactMenu: 303,
    inFront: 304,
  },
  'sticky-layout': 301,
  'sticky-layout-contained': 300,

  autocomplete: 450,
  cart: 460,
  'cookie-notice': 500,
  menu: 1000,

  // NOTE: indices below must be higher than the dialog z-index (1001) from Radix (used by reservoir-kit-ui),
  // otherwise the chakra dialog will be hidden behind the radix dialog, which is problematic when connecting to a wallet
  overlay: 1350,
  modal: 1400,
  'wcm-modal': 1401,
};
