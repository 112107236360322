import { TextProps } from '@chakra-ui/react';
import { type ComponentProps, type FC } from 'react';
import { zeroAddress } from 'viem';
import CryptoCurrencyIcon from './CryptoCurrencyIcon';
import FormatCrypto from './FormatCrypto';

type FormatCryptoCurrencyProps = TextProps & {
  logoHeight?: number;
  address?: string;
  logoSrc?: string;
};

type Props = ComponentProps<typeof FormatCrypto> & FormatCryptoCurrencyProps;

const FormatCryptoCurrency: FC<Props> = ({
  amount,
  address = zeroAddress,
  maximumFractionDigits,
  logoHeight = 8,
  decimals,
  logoSrc,
  ...textProps
}) => {
  return (
    <FormatCrypto
      amount={amount}
      maximumFractionDigits={maximumFractionDigits}
      decimals={decimals}
      textProps={textProps}
    >
      <CryptoCurrencyIcon logoSrc={logoSrc} css={{ height: logoHeight }} address={address} />
    </FormatCrypto>
  );
};

export default FormatCryptoCurrency;
