import {
  GetOrdersAsksV5InfiniteQueryResult,
  GetOrdersAsksV5Params,
  getGetOrdersAsksV5InfiniteQueryOptions,
  useGetOrdersAsksV5Infinite,
} from '@/lib/reservoir/reservoir.generated';
import { isDefined } from '@/types/type-presence';
import { ReservoirChain } from '@/utils/chains';
import { useMarketplaceChain } from './use-marketplace-chain';

export const LISTINGS_ITEMS_PER_PAGE = 9;

const params: Partial<GetOrdersAsksV5Params> = {
  limit: LISTINGS_ITEMS_PER_PAGE,
  includeCriteriaMetadata: true,
  includeDynamicPricing: true,
};

const getListings = (chain: ReservoirChain, override?: Partial<GetOrdersAsksV5Params>) => {
  return getGetOrdersAsksV5InfiniteQueryOptions(
    {
      ...params,
      ...override,
    },
    {
      query: {
        queryKey: ['/orders/asks/v5', chain.id, params, override],
        getNextPageParam: (lastPage: GetOrdersAsksV5InfiniteQueryResult) => lastPage.continuation,
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );
};

const useListings = (override?: Partial<GetOrdersAsksV5Params>) => {
  const chain = useMarketplaceChain();

  const { data, ...request } = useGetOrdersAsksV5Infinite(
    {
      ...params,
      ...override,
    },
    {
      query: {
        queryKey: ['/orders/asks/v5', chain.id, params, override],
        getNextPageParam: (lastPage: GetOrdersAsksV5InfiniteQueryResult) =>
          lastPage.continuation || undefined,
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );

  return {
    data: (data?.pages || []).flatMap(page => page.orders).filter(isDefined),
    ...request,
  };
};

export { getListings, useListings };
