import { normalizeAddress } from '@/utils/addresses';
import { truncate } from '@/utils/truncate';
import { formatWalletAddress } from '@sphere/ui';
import useSWR from 'swr';
import { mainnet } from 'wagmi/chains';

/* Append with wallet address to get ENS details */
export const ENS_RESOLVER_ENDPOINT = 'https://ensdata.net/';

export const getENSDetails = async (address: string) => {
  try {
    const ensResponse = ((await fetch(`${ENS_RESOLVER_ENDPOINT}${address}`).then(res =>
      res.json(),
    )) || {}) as {
      ens?: string;
      avatar_url?: string;
    };

    return {
      name: truncate(ensResponse.ens || '', 24, '…'),
      image: ensResponse.avatar_url,
    };
  } catch {
    return { name: null, image: null };
  }
};

export const useENSResolver = (address?: string, chainId = 1) => {
  const addressLowercase = normalizeAddress(address);
  const isENSAvailable = chainId === mainnet.id;

  const response = useSWR(
    `${ENS_RESOLVER_ENDPOINT}${addressLowercase}`,
    (url: string) => {
      if (!isENSAvailable || !address) {
        return null;
      }
      return fetch(url)
        .then(response => response.json())
        .catch(() => null);
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    },
  );

  const { ens: name, avatar_url: avatar } = (response?.data || {}) as {
    ens?: string;
    avatar_url?: string;
  };

  return {
    ...response,
    address,
    name: response.data?.name,
    displayName: truncate(name || '', 24, '…') || formatWalletAddress(address || ''),
    avatar,
  };
};
