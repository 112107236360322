import { SubmenuScreen } from '@/components/features/layout/Header/useHeaderSettings/useMenu';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { routes } from '@/utils/routes';
import useTranslation from 'next-translate/useTranslation';
import { type HTMLAttributeAnchorTarget } from 'react';

type LinkItem = {
  title: string;
  slug: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
};

type MenuItem = {
  title: string;
  slug: string;
  href?: string;
  links?: LinkItem[];
  submenuScreen?: SubmenuScreen;
  isCreator?: boolean;
  isAdmin?: boolean;
};

export const useMenuItems = (): Record<string, MenuItem> => {
  const { t } = useTranslation('common');
  const marketplaceChain = useMarketplaceChain();

  return {
    create: {
      title: t('header.nav.main-items.create'),
      slug: t('header.nav.main-items.create'),
      href: routes.account.collections.overview,
      isCreator: true,
      isAdmin: true,
    },
    collections: {
      title: t('header.nav.main-items.collections'),
      slug: t('header.nav.main-items.collections'),
      href: routes.collections.overview(marketplaceChain.routePrefix),
    },
    insights: {
      title: t('header.nav.main-items.insights'),
      slug: t('header.nav.main-items.insights'),
      href: routes.insights(marketplaceChain.routePrefix),
    },
    resources: {
      title: t('header.nav.main-items.resources'),
      slug: t('header.nav.main-items.resources'),
      submenuScreen: SubmenuScreen.Resources,
      links: [
        /**
         * We've decided to not launch the News section of Sphere, in order to not delete the templates they can simply be returning 404s for the time being.
         *  { title: t('header.nav.sub-items.news'), href: '/news' },
         */
        {
          title: t('header.nav.sub-items.about'),
          slug: t('header.nav.sub-items.about'),
          href: routes.article.about,
        },
        {
          title: t('header.nav.sub-items.support'),
          slug: t('header.nav.sub-items.support'),
          href: routes.support,
        },
        {
          title: t('header.nav.sub-items.fees'),
          slug: t('header.nav.sub-items.fees'),
          href: routes.article.fees,
        },
        {
          title: t('header.nav.sub-items.dao'),
          slug: t('header.nav.sub-items.dao'),
          href: routes.article.meritCircle,
        },
        {
          title: t('header.nav.sub-items.security'),
          slug: t('header.nav.sub-items.security'),
          href: routes.article.security,
        },
        // {
        //   title: t('header.nav.sub-items.newsletter'),
        //   slug: t('header.nav.sub-items.newsletter'),
        //   href: '/#newsletter',
        // },
      ],
    },
  };
};
