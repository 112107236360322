import { Environment } from '@onbeam/sdk';
import { createEnv } from '@t3-oss/env-nextjs';
import { isAddress } from 'viem';
import { z } from 'zod';

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    // Vercel automatically sets NODE_ENV to 'development' when running `next dev`, or 'production' for all other commands.
    // Meaning NODE_ENV will always be 'production' when deployed to Vercel, even for preview deployments.
    NODE_ENV: z.enum(['development', 'preview', 'production']).default('development'),
    IS_PROD: z
      .string()
      .optional()
      .default('false')
      .transform(s => s !== 'false' && s !== '0'),
    DATABASE_URL: z.string().url(),
    NEXTAUTH_URL: z.string().url(),
    // NEXTAUTH_URL: z.preprocess(
    //   str => process.env.VERCEL_URL ?? str,
    //   process.env.VERCEL ? z.string() : z.string().url(),
    // ),
    NEXTAUTH_SECRET: z.string(),
    RESERVOIR_SWAGGER_FILE: z.string().url(),
    RESERVOIR_API_KEY: process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    RESERVOIR_BEAM_API_KEY:
      process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    RESERVOIR_IMMUTABLE_API_KEY:
      process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    S3_MEDIA_BUCKET_NAME: z.string(),
    S3_MEDIA_ENDPOINT: z.string().url(),
    AWS_ACCESS_KEY_ID: process.env.NODE_ENV === 'development' ? z.string().optional() : z.string(),
    AWS_SECRET_ACCESS_KEY:
      process.env.NODE_ENV === 'development' ? z.string().optional() : z.string(),
    AWS_DEFAULT_REGION: process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    AWS_REGION: process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    SENTRY_AUTH_TOKEN: process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    COINGECKO_API_KEY: process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_HOST_URL: z.preprocess(
    //   str => process.env.VERCEL_URL ?? str,
    //   process.env.VERCEL ? z.string() : z.string().url(),
    // ),
    NEXT_PUBLIC_HOST_URL: z.string(),
    NEXT_PUBLIC_ALCHEMY_RPC_KEY: z.string(),
    NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID: z.string(),
    NEXT_PUBLIC_RESERVOIR_WEBSOCKET_KEY: z.string(),
    NEXT_PUBLIC_RESERVOIR_BEAM_WEBSOCKET_KEY: z.string(),
    NEXT_PUBLIC_RESERVOIR_IMMUTABLE_WEBSOCKET_KEY: z.string(),
    NEXT_PUBLIC_BEAM_ENVIRONMENT: ['preview', 'localhost'].some(str =>
      process.env.NEXT_PUBLIC_HOST_URL?.includes(str),
    )
      ? z.nativeEnum(Environment)
      : z.nativeEnum(Environment).optional(),
    NEXT_PUBLIC_BEAM_PUBLISHABLE_KEY: ['preview', 'localhost'].some(str =>
      process.env.NEXT_PUBLIC_HOST_URL?.includes(str),
    )
      ? z.string()
      : z.string().optional(),
    NEXT_PUBLIC_IMMUTABLE_PUBLIC_KEY: z.string(),
    NEXT_PUBLIC_IMMUTABLE_CLIENT_ID: z.string(),
    NEXT_PUBLIC_MARKETPLACE_SUPPORTED_CHAINS: z.string(),
    NEXT_PUBLIC_MARKETPLACE_FEE: z.coerce.number().min(0).max(10000),
    NEXT_PUBLIC_MARKETPLACE_FEE_RECIPIENT: z.string().refine(isAddress),
    NEXT_PUBLIC_NORMALIZE_ROYALTIES: z.boolean().default(false),
    NEXT_PUBLIC_MARKETPLACE_SOURCE: z.string(),
    NEXT_PUBLIC_SENTRY_DSN:
      process.env.NODE_ENV === 'production' ? z.string() : z.string().optional(),
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.string().optional(),
    NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE: z.string().optional(),
    NEXT_PUBLIC_FATHOM_ID: z.string().optional(),
    NEXT_PUBLIC_OTLP_SERVICE_NAME: z.string().optional(),
    NEXT_PUBLIC_OTLP_ENVIRONMENT: z.string().optional(),
    NEXT_PUBLIC_OTLP_COLLECTOR_URL: z.string().optional(),
  },

  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    IS_PROD: process.env.IS_PROD,
    DATABASE_URL: process.env.DATABASE_URL,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    RESERVOIR_SWAGGER_FILE: process.env.RESERVOIR_SWAGGER_FILE,
    RESERVOIR_API_KEY: process.env.RESERVOIR_API_KEY,
    RESERVOIR_BEAM_API_KEY: process.env.RESERVOIR_BEAM_API_KEY,
    RESERVOIR_IMMUTABLE_API_KEY: process.env.RESERVOIR_IMMUTABLE_API_KEY,
    S3_MEDIA_BUCKET_NAME: process.env.S3_MEDIA_BUCKET_NAME,
    S3_MEDIA_ENDPOINT: process.env.S3_MEDIA_ENDPOINT,
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    AWS_DEFAULT_REGION: process.env.AWS_DEFAULT_REGION,
    AWS_REGION: process.env.AWS_REGION,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    COINGECKO_API_KEY: process.env.COINGECKO_API_KEY,
    NEXT_PUBLIC_HOST_URL: process.env.NEXT_PUBLIC_HOST_URL,
    NEXT_PUBLIC_ALCHEMY_RPC_KEY: process.env.NEXT_PUBLIC_ALCHEMY_RPC_KEY,
    NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID,
    NEXT_PUBLIC_RESERVOIR_WEBSOCKET_KEY: process.env.NEXT_PUBLIC_RESERVOIR_WEBSOCKET_KEY,
    NEXT_PUBLIC_RESERVOIR_BEAM_WEBSOCKET_KEY: process.env.NEXT_PUBLIC_RESERVOIR_BEAM_WEBSOCKET_KEY,
    NEXT_PUBLIC_RESERVOIR_IMMUTABLE_WEBSOCKET_KEY:
      process.env.NEXT_PUBLIC_RESERVOIR_IMMUTABLE_WEBSOCKET_KEY,
    NEXT_PUBLIC_BEAM_ENVIRONMENT: process.env.NEXT_PUBLIC_BEAM_ENVIRONMENT,
    NEXT_PUBLIC_BEAM_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_BEAM_PUBLISHABLE_KEY,
    NEXT_PUBLIC_IMMUTABLE_PUBLIC_KEY: process.env.NEXT_PUBLIC_IMMUTABLE_PUBLIC_KEY,
    NEXT_PUBLIC_IMMUTABLE_CLIENT_ID: process.env.NEXT_PUBLIC_IMMUTABLE_CLIENT_ID,
    NEXT_PUBLIC_MARKETPLACE_SUPPORTED_CHAINS: process.env.NEXT_PUBLIC_MARKETPLACE_SUPPORTED_CHAINS,
    NEXT_PUBLIC_MARKETPLACE_FEE: process.env.NEXT_PUBLIC_MARKETPLACE_FEE,
    NEXT_PUBLIC_MARKETPLACE_FEE_RECIPIENT: process.env.NEXT_PUBLIC_MARKETPLACE_FEE_RECIPIENT,
    NEXT_PUBLIC_NORMALIZE_ROYALTIES: process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES,
    NEXT_PUBLIC_MARKETPLACE_SOURCE: process.env.NEXT_PUBLIC_MARKETPLACE_SOURCE,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE: process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
    NEXT_PUBLIC_FATHOM_ID: process.env.NEXT_PUBLIC_FATHOM_ID,
    NEXT_PUBLIC_OTLP_SERVICE_NAME: process.env.NEXT_PUBLIC_OTLP_SERVICE_NAME,
    NEXT_PUBLIC_OTLP_ENVIRONMENT: process.env.NEXT_PUBLIC_OTLP_ENVIRONMENT,
    NEXT_PUBLIC_OTLP_COLLECTOR_URL: process.env.NEXT_PUBLIC_OTLP_COLLECTOR_URL,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  emptyStringAsUndefined: true,
});
