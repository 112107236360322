import { formatTokenAmount } from '@/utils/formatTokenAmount';
import { Text, TextProps } from '@chakra-ui/react';

type Props = TextProps & {
  usdValue: number;
  /** If false an approximation symbol will be rendered before the amount */
  isExact?: boolean;
  prefix?: string;
  suffix?: string;
};

// This component used to do an API call for conversions. Do we still need that?
export const UsdPrice = ({ usdValue, isExact = false, prefix, suffix, ...textProps }: Props) => (
  <Text as="span" {...textProps}>
    {prefix}
    {!isExact && `≈ `}
    {formatTokenAmount(usdValue, 'fiat', '$')}
    {suffix}
  </Text>
);
