const wrappedContracts: Record<number, string> = {
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // mainnet
  11155111: '0x5300000000000000000000000000000000000004', // sepolia
  137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', // polygon
  80001: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889', // polygon mumbai
  43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7', // avalance
  43113: '0xd00ae08403b9bbb9124bb305c09058e32c39a48c', // avalance fuji
  4337: '0xD51BFa777609213A653a2CD067c9A0132a2D316A', // beam
  13337: '0xF65B6f9c94187276C7d91F4F74134751d248bFeA', // beam testnet
  13371: '0x3A0C2Ba54D6CBd3121F01b96dFd20e99D1696C9D', // immutable zkEvm
  13473: '0x1CcCa691501174B4A623CeDA58cC8f1a76dc3439', // immutable zkEvm testnet
};

export default wrappedContracts;
